<template>
    <painel titulo="Email" icone="pi pi-key" :refreshFunction="atualizar" v-show="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data envio/Tentativa (DE)</label>
                    <Calendar v-model="dataAtualizacaoDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data envio/Tentativa (ATÉ)</label>
                    <Calendar v-model="dataAtualizacaoAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
            </div>
        </Panel>
        <tabela
            :data="emails"
            dataKey="emailId"
            :globalFilterFields="['modulo', 'subModulo', 'destinatario', 'titulo', 'enviadoDesc', 'mensagem', 'dataAtualizacao']"
            stateKey="dt-emails"
        >
            <template #botoes> </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :email="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="modulo" header="Módulo" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.modulo }}
                    </template>
                </Column>
                <Column field="subModulo" header="SubMódulo" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.subModulo }}
                    </template>
                </Column>
                <Column field="destinatario" header="Destinatários" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.destinatarios }}
                    </template>
                </Column>
                <Column field="titulo" header="Título" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.titulo }}
                    </template>
                </Column>
                <Column field="origemEmail" header="Origem" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.origemEmailDesc }}
                    </template>
                </Column>
                <Column field="enviadoDesc" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.status"></status>
                    </template>
                </Column>
                <Column field="dataAtualizacao" header="Data Envio/Tentativa" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import EmailsServices from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            emails: null,
            dataAtualizacaoAte: null,
            dataAtualizacaoDe: null,
            carregado: false,
        };
    },

    created() {
        this.dataAtualizacaoAte = new Date();
        this.dataAtualizacaoDe = new Date(this.$moment(this.dataAtualizacaoAte).subtract(1, 'months').format('YYYY-MM-DD'));
    },

    methods: {
        obterEmails() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataAtualizacaoDe: this.dataAtualizacaoDe ? this.$moment(this.dataAtualizacaoDe).format('YYYY-MM-DD') : '',
                dataAtualizacaoAte: this.dataAtualizacaoAte ? this.$moment(this.dataAtualizacaoAte).format('YYYY-MM-DD') : '',
            };
            EmailsServices.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.emails = response.data;
                } else {
                    this.emails = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterEmails();
        },
    },

    mounted() {
        this.obterEmails();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'GestaoEmails_Emails';
        },
        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'GestaoEmails_Emails') {
                if (this.$store.getters.atualizar) {
                    this.obterEmails();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
        dataAtualizacaoDe() {
            if (this.carregado) {
                this.obterEmails();
            }
        },

        dataAtualizacaoAte() {
            if (this.carregado) {
                this.obterEmails();
            }
        },
    },
};
</script>
