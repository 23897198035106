<template>
    <font-awesome-icon icon="info-circle" @click="toDetalhar()" size="lg" class="icon-button p-text-primary" title="Detalhar" v-if="$temAcessoView('EMAIL-DETALHAR-00')" />
</template>

<script>
export default {
    props: {
        email: {
            type: Object,
        },
    },

    methods: {
        toDetalhar() {
            this.$router.push({
                name: 'GestaoEmails_Emails_Detalhar',
                params: {
                    id: this.email.emailId,
                },
            });
        },
    },
};
</script>
